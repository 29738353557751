.headerContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #f5f5f5;
}

.inputSearch {
  width: 300px;
}

.tableContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.modalMainContainer {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
}

.modalHeaderContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.updateMemberContainer  {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 576px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .inputSearch {
    width: 300;
  }
  .modalHeaderContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .tableContainer {
    padding: 0px;
  }
}
