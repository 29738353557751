.HiddenColumn {
  display: none;
}

.antd-table-custom .ant-table-cell {
  padding: 8px !important;
}

/* [20/01/2025][#21321][phuong_td] điều chỉnh css cho checkbox */
.container-checkbox {
  width: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}