.header {
  border-bottom: 1px solid #e8e8e8;
  background-color: #fafafa;
  height: 50px;
  display: flex;
  overflow-x: auto; /* Cho phép cuộn ngang nếu cần */
}

.header-col {
  border-right: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  height: 50px;
  flex: 1;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}

.folder-container {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.folder-style {
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  transition: transform 0.3s ease;
}
.folder-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.folder-icon {
  margin-top: 60px;
  font-size: 60px;
}
.folder-details {
  border-radius: 3px;
  padding: 8px;
  background-color: rgba(244, 246, 247, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
  height: 40px;
  overflow: hidden;
  margin-top: 60px;
}
.folder-text {
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 576px) {
  .folder-style {
    margin-bottom: 16px;
  }

  .ellipsis-icon {
    font-size: 18px;
  }
}
.ellipsis-icon {
  font-size: 24px;
  border-radius: 50%;
  background-color: #e8e8e8;
}
.checkbox-icon {
  font-size: 20px;
}
.dropdown-card {
  width: 230px;
  height: 190px;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding-left: 20px;
  z-index: 1000;
  margin-top: 5px;
  margin-left: 270px;
}
.dropdown-card::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.custom-modal {
  width: 500px !important;
}
.ant-upload-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.note {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}
.modal-header {
  margin-left: 35px;
  font-size: 16px;
  font-weight: 700;
}
.custom-button {
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 20px;
  padding: 0;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #f0f0f0;
}
