.container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .topper {
        background-color: white;
        width: 100%;
        padding: 1rem;
        .title {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }
    .main {
        background-color: white;
        flex: 1;
        max-height: calc(100dvh - 47px - 64px);
        overflow: auto;
        section {
            width: 100%;
            &:not(.hero) {
                padding: 1rem;
                padding-bottom: 2rem;
            }
            &.projects {
                background-color: whitesmoke;
            }
            .titleContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;
            }
        }
        .hero {
            .heroImg {
                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
        }
        .news {
            display: flex;
            flex-direction: column;
            .cardNews {
                background-color: white;
                height: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid black;

                border-radius: 8px;
                border: none;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                cursor: pointer;
                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    .cardTitle {
                        color: #edaa3b;
                    }
                }
                
                .cardImg {
                    width: 100%;
                    aspect-ratio: 14 / 10;
                    overflow: hidden;
                    border-radius: 5px 5px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .inforWrapper {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    padding: .75rem .5rem 1rem;
                    .cardTitle {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        max-height: 2.8em;
                        line-height: 1.4em;
                        margin: 0;
                        margin-bottom: 0.5rem;
                        transition: all 200ms ease-in-out;
                        font-size: 16px;
                        // min-height: 45px;
                      }
                      
                      .cardDesc {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3; /* number of lines to show */
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        max-height: 4.2em;
                        line-height: 1.4em;
                        // min-height: 60px;
                        font-size: 12px;
                        & * {
                            font-size: 12px !important;
                        }
                      }
                      
                }
            }
        }
        .projects {
            padding-bottom: 1rem !important;
            .serviceCard {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                overflow: hidden;
                width: 100%;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                border: 10px solid white;
                cursor: pointer;
                margin-bottom: 1.5rem;
                &:hover {
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2) !important;
                    .serviceTitle {
                        top: 60%
                    }
                }
                .serviceImg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    text-align: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .serviceTitle {
                    position: absolute;
                    inset: 0;
                    top: 100%;
                    transition: all 100ms ease-in-out;
                    h4 {
                        height: 100%;
                        width: 100%;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        background-color: #e2dedea2;
                        padding-inline: .5rem;
                        margin: 0;
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }
            
        }
        .introduction {
            .introductionNews {
                // display: flex;
                // flex-wrap: wrap;
                // flex-direction: row;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
                gap: 2rem;
                max-width: 1200px;
                margin: auto;
                // .imgContainer,.inforWrapper {
                //     flex: 1 400px 300px;
                //     // min-width: 300px;
                // }
                .imgContainer {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    max-width: 600px;
                    margin-inline: auto;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                
                }
                .inforWrapper {
                    .desc {
                        margin-bottom: .5rem;
                    }
                }
            }
        }
    }
}
.swiper {
    padding: 5px
}
.swiper-slide {
    height: auto;
}

.cardNewsMini {
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: #000;
    &:hover {
        .cardTitle {
            color: #edaa3b;
        }
        .cardImg {
            border: 1px solid rgb(153 153 153 / 50%);
        }
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        padding: 0.25rem;
        justify-content: space-between;
        max-width: 60%;
    }
    .extraInfor {
        display: flex;
        gap: .5rem 1rem;
        flex-wrap: wrap;
        & > div {
            line-height: 15px;
            font-size: 12px;
            color: #00000093;
            display: flex;
            align-items: center;
            gap: 4px
        }
    }
    .cardImg {
        height: 100%;
        display: flex;
        min-width: 40%;
        max-width: 40%;
        border: 1px solid rgb(153 153 153 / 20%);
        /* box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px; */
        filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.014));
        border-radius: 6px;
        background-color: white;
        width: 100%;
        aspect-ratio: 14 / 10;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .cardTitle {
        display: flow-root;
        font-weight: 500;
        max-height: 2.6em;
        line-height: 1.3em;
        transition: all 200ms ease-in-out;
        font-size: 15px;
        min-height: 2.6em;
    }
    
    .cardDesc {
        max-height: 5.2em;
        line-height: 1.2em;
        min-height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-wrap: break-word;
        // min-height: 60px;
        font-size: 12px;
        & * {
            font-size: 12px !important;
        }
    }
}

.footer {
    padding: 0;
    .footerContainer {
        display: flex;
        flex-direction: column;
        color: #9EB7CB;
        .footerWrapper {
            background-color: #0C2B69;
            padding: 2rem 1rem 1.5rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
            gap: 1.25rem;
            .col {
                .footerLogo {
                    max-width: 100%;
                    max-height: 40px;
                    img {
                        height: auto;
                        object-fit: cover;
                    }
                }
                .title {
                    color: white;
                    font-weight: bold;
                    font-size: 18px;
                    text-transform: uppercase;
                }
                .navigationList {
                    transform: translateY(-2px);
                    & > div {
                        line-height: 22px;
                    }
                }
            }
        }
        .copyright {
            background-color: #001C52;
            padding: .5rem 1rem;
        }
    }
}

@media screen and (max-width: 576px) {
    .main {
        section {
            &:not(.hero) {
                padding: .25rem !important;
            }
            .titleContainer > div {
                font-size: 1.35rem !important;
            }
        }
    }
    .projects {
        .serviceCard {
            .serviceTitle {
                top: 60% !important
            }
        }
    }
    .introduction {
        .introductionNews {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)) !important;
            gap: 1rem !important;
            padding-bottom: 1rem;
        }
    }
}