.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-inline: 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F5F5F5;
}

.body {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
}

.stepper {
  width: 70%;
  display: flex;
  justify-content: center;
}

.stepperContent {
  width: 90%;
  height: auto;
  padding: 20px;
}

@media only screen and (max-width: 1250px) {
  .body {
    padding: 10px;
  }
  .stepper {
    width: 100%;
  }
  .stepperContent {
    width: 100%;
    padding: 10px;
  }
}
