.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 6px;
}

.projectAvatarContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
