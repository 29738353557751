.container {
  width: 1240px;
  height: 700px;
}
.formcontainer {
  margin-left: 20px;
  width: 1128px;
  height: 400px;
  flex-direction: column;
  align-items: center;
}
.formtitle {
  font-size: 24px;
  margin-bottom: 20px;
}
.subsection {
  display: flex;
  margin-bottom: 10px;
}
.labeltext {
  font-weight: 500;
  font-size: 14px;
}
.labeltext:nth-of-type(2) {
  margin-left: 170px;
}
.labeltext:nth-of-type(3) {
  margin-left: 120px;
  margin-right: 140px;
}
.inputwrapper {
  width: 232px;
  height: 32px;
  margin-bottom: 10px;
}
.inputfield {
  color: rgba(24, 144, 255, 1);
  text-decoration: underline;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 180px;
  height: 32px;
  font-size: 14px;
}
.inputfield:hover {
  color: rgba(24, 144, 255, 1);
}
.selectdatesection {
  width: 432px;
  height: 32px;
  margin-left: 230px;
  margin-top: -42.5px;
}
.selectfield {
  font-size: 14px;
  width: 400px;
  height: 32px;
}
.datewrapper {
  margin-top: -33px;
  margin-left: 670px;
}
.datepicker {
  width: 200px;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 1;
  font-size: 14px;
}
.inputDate01 {
  width: 200px;
  left: 910px;
  top: -33px;
}
.buttonsection {
  margin-top: 300px;
  display: flex;
  justify-content: space-between;
}
.PrinterOutlined {
  font-size: 16px;
  width: 44px;
  height: 30px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 880px;
}
.button:first-child {
  background-color: #ccc;
  margin-left: 35px;
}
.button:last-child {
  margin-right: 90px;
}
.addButton {
  background-color: rgba(250, 250, 250, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 0;
  height: 25px;
  width: 100%;
}
.newRow {
  text-align: center;
  width: 100%;
}
.underlineText {
  color: rgba(24, 144, 255, 1);
  text-decoration: underline;
}
.blurText {
  color: rgba(140, 140, 140, 1);
}
