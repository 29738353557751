.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 350px  ;
}

.inputLabel {
 
  left: 8px;
  z-index: 1;
  pointer-events: none;
  color: black

}

.inputField {
  width: 250px;
  margin-left: 8px;
}
