.projectAvatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.emp-photo .ant-upload.ant-upload-select {
  width: 250px !important;
  height: 250px !important;
}

.functionContainer {
  background-color: white;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-top: 0.1px solid #e4e4e4;
  border-bottom: 0.1px solid #e4e4e4;
}

.tableContainer {
  width: 100%;
  height: 88%;
  display: flex;
  justify-content: center;
  padding: 5px;
}

@media only screen and (max-width: 576px) {
  .functionContainer {
    // flex-direction: column;
    gap: 10px;
  }
}