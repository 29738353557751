.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bodyContainer {
  width: 80%;
  height: auto;
  padding: 20px 40px;
}

.bodyTopContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .bodyContainer {
    width: 95%;
    gap: 10px;
    padding: 10px 10px;
  }
}