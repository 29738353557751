.proposalList {
  display: flex;
  gap: 10px ;
  padding-right: 16px; 
  margin-top: -5px;
  height: 80vh;
  overflow-x: auto;
  overflow-y: hidden;

} 
.confirmlevel {
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center;    /* Căn giữa theo chiều dọc */
  flex-direction: column; /* Sắp xếp text theo cột */
}
.proposalDayCard { 
  // margin-bottom: 20px;
  background: -webkit-linear-gradient(top, #c8c8ca 65px, #fdfcfc 40px);
  border-radius: 20px;
  min-width: 40%;
  height: 100%;
  padding-top: -5px;
  flex-grow: 1;
} 
.proposalDayCardTitle {
  font-weight: bold;
  font-size: 16px;
  height: 20px;
}
.ant-modal-content {
  height: 100%;
  display: flex;
  overflow: 'hidden';
}
.proposalDayCardContent {
  padding-top: 10px;
  margin-top: 20px;
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
   height: 70vh ; /* Đặt chiều cao tối đa */
} 
.proposalCard {
  overflow: hidden;
  margin-bottom: 20px;
  border-width: 2px;
  border-style: solid;
  width: 100%;
}

.proposalCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // overflow: hidden; /* Đảm bảo phần chứa không tràn ra ngoài */
  & .project {
    flex: 1;
    margin-right: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Giới hạn tối đa 2 dòng */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Thêm dấu "..." khi văn bản tràn */
    white-space: normal; /* Cho phép xuống dòng */
}
  & .buttonGroup {
    display: flex;
    padding-top: 2px;
    gap: 2px;
  }
}
.noteText {
  font-size: x-small;
  opacity: 0.6;
}
.proposalCardFooter {
  display: flex;
  // align-items: center;
  // padding-right: 2px;
  background-color: #ffffff;

  & .id {
    flex: 1;
    text-align: right;
    margin-right: 2px;

  }

  & .section {
    flex: 1;
    text-align: right;
  }
}

.proposalCardBody {
  margin-top: -15px;
  // margin-left: -15px;
  padding-bottom: 20px;

}
.textWhite {
  color: white;
}
.textHighlight {
  color: white;
  font-size: larger;
  // font-weight: bolder;
}
.orange {
  border-color: #FFA940;
  background: linear-gradient(to bottom, #fdfcfb 57px, #FFA940 30px);
  border-radius: 20px;
}

.green {
  border-color: #389E0D;
  background: linear-gradient(to bottom, #fdfcfb 58px, #389E0D 33px);
  border-radius: 20px;
}

.blue {
  border-color: #1D39C4;
  background: linear-gradient(to bottom, #fdfcfb 75px, #1D39C4 38px);
 border-radius: 20px;
} 
.red {
  border-color: #eb1e4b;
  background: linear-gradient(to bottom, #fdfcfb 57px, #eb1e4b 30px);
 border-radius: 20px;
} 

.noBorderButton {
  border: none ;
  box-shadow: none ;
  background: transparent ;
  padding: 0 ;
}
.yellow {
  border-color: #FFD700; /* Màu viền vàng */
  background: linear-gradient(to bottom, #fdfcfb 57px, #FFD700 30px); /* Màu nền vàng */
  border-radius: 20px;
}

.cyan {
  border-color:  #00FFFF;
  background: linear-gradient(to bottom, #fdfcfb 57px, #00FFFF 30px); /* Màu nền xám */
  border-radius: 20px;
} 
.magenta {
  border-color: #CC6699;
  background: linear-gradient(to bottom, #fdfcfb 75px, #CC6699 38px); /* Màu nền xám */
  border-radius: 20px;
} 