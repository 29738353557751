.titleContainer {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.informationContainer {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.informationTitle {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
}

.dataContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dataLabel {
  font-weight: 700;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-block: 20px;
}

.buttonWithIcon {
  height: 40px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: medium;
}

@media only screen and (max-width: 992px) {
  .titleContainer {
    width: 100%;
    gap: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .buttonContainer {
    flex-direction: column;
    gap: 10px;
  }
}