.mainContainer {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projectContainer {
    height: calc(100dvh - 60px - 48px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 5px 10px;
    min-width: 100%;
}

.topper {
    width: 100%;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #FFFFFF;
    border-bottom: 3px solid #F5F5F5;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 24px;
    padding-left: 10px;
}

.inputSearch {
    width: 260px;
    margin-right: 20px;
    padding-top: -5px;
}

.projectList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
    gap: 40px;
    margin-top: 20px;
    padding-block: 10px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.projectList::-webkit-scrollbar,
.projectList::-webkit-scrollbar-track,
.projectList::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
}

.projectList::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.scrollAble::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.scrollAble::-webkit-scrollbar
{
    width: 4px;
    height: 4px;
    background-color: #F5F5F5 !important;
}

.scrollAble::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2);
}

@media only screen and (max-width: 755px) {
    .header {
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }
    .topper {
        flex-direction: column;
        gap: 10px;
    }
    .inputSearch {
        width: 100%;
    }
}

