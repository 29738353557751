.footerContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.buttonWithIcon {
  height: 40px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: medium;
}
.warehouseContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 30px;
  padding-bottom: 0;
  background-color: #FFFFFF;
  border-radius: 6px;
}