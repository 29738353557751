.tab-header-diary {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: white;
}
.apply-button {
  margin-right: 10px;
}
.download-button {
  background-color: white;
  color: black;
  border-color: rgba(24, 144, 255, 1);
  margin-right: 10px;
}
.ellips-button {
  background-color: white;
  color: black;
  border-color: rgba(24, 144, 255, 1);
  margin-right: 10px;
}
.tablecell {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.tabheader {
  background-color: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.headerContent {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.textGroup {
  margin-left: 20px;
  margin-top: 1px;
  font-size: 16px;
  font-weight: bold;
}
.dropdown {
  width: 200px;
}
