
@import url('../../common/styles.less');
.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 10px;
}

.headerLeft {
  // width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


@media only screen and (max-width: 670px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerLeft {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
  }
}

.formatCheckbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  margin-bottom: -32px;
}

.symbol{
  color: red;
}
.formatCol {
  display: flex;
  justify-content: space-between;
}
.wrapperPublicPage {
  
  :global(.ant-table-container .ant-table-tbody > tr.ant-table-row .ant-table-cell:nth-child(3) .ant-btn ) {
   border: none;
  }
  // @media only screen and (max-width: 1200px) {
  //   :global(.ant-table-container .ant-table-tbody > tr.ant-table-row .ant-table-cell:nth-child(3) .ant-space-item ) {
  //     max-width: 250px;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //    }
  // }
  // @media only screen and (min-width: 1200px) {
  //   :global(.ant-table-container .ant-table-tbody > tr.ant-table-row .ant-table-cell:nth-child(3) .ant-space-item ) {
  //     max-width: 290px;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //    }
  // }
   :global(.ant-pagination) {
    display: none;
   }

   @media only screen and (min-height: 911px) {
    :global(.ant-table-body) {
      max-height: 690px !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    :global(.ant-table-container .ant-table-body) {
      max-height: 60vh !important;
    }
    }

    @media only screen and (max-width: 992px) {
      /* CSS cho máy tính bảng ngang hoặc thiết bị nhỏ */
      :global(.ant-table-container .ant-table-body) {
        max-height: 55vh !important;
      }
    }
  

  :global(.ant-table-container .ant-table-thead > tr > th:nth-child(3)) {
    text-align: center !important;
  }

  :global(.ant-table-container .ant-table-tbody > tr.ant-table-row .ant-table-cell:nth-child(9) .ant-select-selector) {
    border: none;
    border-color: transparent !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px !important;
    background: transparent;
    margin-top: 2px;
  }

  
  :global(.ant-table-container .ant-table-tbody > tr.ant-table-row .ant-table-cell:nth-child(9)  .ant-select-arrow) {
    margin-right: 25px !important;
    color: black;
  }
  :global(.ant-table-container .ant-table-tbody > tr.ant-table-row .ant-table-cell:nth-child(9)  .ant-select-selection-item) {
    padding-inline-end : 14px !important;
    padding-inline-start: 5px !important;
  }
}


@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	50%{
		transform: translateY(4%);
	}
	65%{
		transform: translateY(-2%);
	}
	80%{
		transform: translateY(2%);
	}
	95%{
		transform: translateY(-1%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:first-child {
  font-weight: 700;
}
.popup li:not(:first-child) span {
  padding-right: 10px;
}

.popup li:last-child {
  padding-top: 0px;
  color: red;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}
.Public__wrapper{
  
}

:global(.ant-modal-body .ant-row) {
 row-gap: 3px !important;
}
 
// /* Custom scrollbar styles */
// :global(.custom-modal-scrollbar::-webkit-scrollbar) {
//   width: 12px; /* Độ rộng của thanh cuộn */
// }

// :global(.custom-modal-scrollbar::-webkit-scrollbar-track) {
//   border: 1px solid #ccc; /* Viền của track */
//   background: #f1f1f1; /* Màu nền của track */
//   border-radius: 10px;
// }

// :global(.custom-modal-scrollbar::-webkit-scrollbar-thumb) {
//   background: #888; /* Màu của thanh cuộn */
//   border-radius: 10px;
//   border: 2px solid #ccc; /* Viền của thanh cuộn */
// }

// :global(.custom-modal-scrollbar::-webkit-scrollbar-thumb:hover) {
//   background: #555; /* Màu của thanh cuộn khi hover */
// }
