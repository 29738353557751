.headerContainer { 
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  border-bottom: 0.1px solid #e4e4e4;
}
.headerLeft {
  // width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 670px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerLeft {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
  }
}

.scrollable {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  display: 'flex';
  flex-direction: 'column';
  padding: 15px 15px;
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.inputItem {
  width: 100%;
}

.row {
  display: 'flex';
  flex-direction: 'row';
  background: '#fff';
}
