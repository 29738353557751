.tableContainer {
  background-color: white;
  margin: 5px;
  border-radius: 5px;
}

.headerContainer h3 {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
}
.headerContainer {
  display: grid;
  grid-template-columns: 5% 20% 10% 10% 10% 10%;
  width: 100%;
}
.headerMa {
  grid-column: 1;
  text-align: left;
}
.headerCongtrinh {
  grid-column: 2;
  text-align: left;
}
.headerMavattu {
  grid-column: 3;
  text-align: left;
}
.headerTenvattu {
  grid-column: 4;
  text-align: left;
}
.headerDonvitinh {
  grid-column: 5;
  text-align: left;
}
.headerSoluong {
  grid-column: 6;
  text-align: left;
}
.headerCongtrinhnhan {
  grid-column: 7;
  text-align: left;
}
.headerDot {
  grid-column: 8;
  text-align: left;
}
.underlineText {
  color: rgba(24, 144, 255, 1);
  text-decoration: underline;
}
.tableDate {
  font-size: 14px;
  padding: -5px;
  height: 18px;
}
/* [23/10/2024][#20578][phuong_td] Thêm màu cho header */
.headerColor {
  /* background-color: rgba(24, 144, 255, 0.7) !important; */
  background-color: rgba(255, 255, 255, 1) !important;
  color: #000000;
}
/* [23/10/2024][#20578][phuong_td] Bỏ qua việc thay đổi màu của row khi hover */
:global(.ant-table-container .ant-table-cell-row-hover) {
  background-color: inherit !important;
}

