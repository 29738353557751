.mainContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.addMemberContainer {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 6px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.headerRight {
  display: flex;
  gap: 5px;
  align-items: center;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.buttonWithIcon {
  height: 40px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: medium;
}

@media only screen and (max-width: 1250px) {
  .addMemberContainer {
    width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}
