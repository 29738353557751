/** Scrollable **/
@bg-color-scroll: #aab2bf;
@border-radius-scroll: 10px;
@bg-color-scroll-2: #d9d9d9;
@bg-color-scoll-track: #d7d7d7;
@bg-color-scroll-thumb: #aab2bfbc;
@bg-color-scroll-thumb-hover: #8f99a9;

.scroll-custom-width(@width) {
    &::-webkit-scrollbar {
      width: @width !important;
      height: @width !important;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent !important;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-button{
      display: none;
      background-color: transparent;
      visibility: hidden;
    }
    &:hover {
      &::-webkit-scrollbar-thumb{
        background: @bg-color-scroll-thumb !important;
        border-radius: @border-radius-scroll;
      }
      &::-webkit-scrollbar-thumb:hover{
        background: @bg-color-scroll-thumb-hover !important;
      }
      &::-webkit-scrollbar-track{
        border-radius: @border-radius-scroll;
        background: transparent;
      }
    }
  }
  

.custom_scrollbar {
  .scroll-custom-width(6px);
}

.vis-vertical-scroll {
  .scroll-custom-width(6px);
}

/** end scroll **/

/** custom antd table scrollbar */
.ant-table-body {
  overflow: auto !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 @border-radius-scroll @bg-color-scroll-2;
  border-radius: @border-radius-scroll;
  background: @bg-color-scoll-track;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: @bg-color-scroll;
  border-radius: @border-radius-scroll;
}
/** end custom antd table scrollbar */

.app-upload-list-item:hover {
  background-color: #D9D9D9;
}

/** global custom antd */
.ant-form-item {
  margin-bottom: 10px;
}
// .ant-form-item-label {
//   padding-bottom: 3px !important;
//   font-weight: 600;
// }

.team-shifts {
  .ant-form-item-label {
    font-weight: 600;
  }
}
.team-modal {
  .ant-modal-footer {
    margin-top: 0
  }
}
.ant-table-row-first {
  width: 100%;
}
/** end global custom antd */
