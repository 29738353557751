.tablecell {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.underlineText {
  color: rgba(24, 144, 255, 1);
  text-decoration: underline;
}

.rowItem {
  display: flex;
  align-items: center;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height: 30px;
  padding: 5px;
}

.model {
  width: 100%;
  height: 370px;
}
.fontMedium {
  font-size: 14px;
  font-weight: 600;
}

.truncateText {
  white-space: nowrap; /* Không cho phép xuống dòng */
  overflow: hidden; /* Cắt bớt nội dung vượt quá */
  text-overflow: ellipsis; /* Thêm dấu chấm lửng (...) */
  max-width: 100%; /* Đặt độ rộng tối đa theo ý bạn */
}
