.tableContainer {
  background-color: white;
  margin: 5px;
  padding: 5px;
  border-radius: 8px;
}
.tableHeader {
  margin-bottom: 7px;
}
.headerContainer {
  display: grid;
  grid-template-columns: 19% 18% 19% 20% 15%;
  width: 100%;
}
.headerContainer h3 {
  margin-left: 18px;
  font-size: 14px;
  font-weight: bold;
}
.headerDate{
  grid-column: 1;
  text-align: left;
}
.headerdescription{
  grid-column: 2;
  text-align: left;
}

.headerQuantity {
  grid-column: 3;
  text-align: left;
}

.headerpayment {
  grid-column: 4;
  text-align: left;
}

.underlineText {
  color: rgba(24, 144, 255, 1);
  text-decoration: underline;
}
.tablecontractor {
  margin-left: 10px;
}
.contractorRow {
  display: grid;
  grid-template-columns: 19%  22%;
  justify-content: space-between;
  align-items: center;
}

.tablecontractor {
  grid-column: 1;
  margin-left: 10px;
  font-size: 14px;
}

.totalCumulative {
  font-size: 14px;
  grid-column: 2;
  text-align: left;
}